import { useAtomValue } from "jotai";
import { useState } from "react";

import { productGroupsState } from "../../jotai/products";
import { Typography } from "../../library";
import PaginatedListButtons from "../../library/PaginatedListButtons";
import ProductGroup from "./ProductGroup";

const GROUPS_PER_PAGE = 3;

interface ProductGroupsProps {
  showTitle?: boolean;
}

export default function ProductGroups({ showTitle }: ProductGroupsProps) {
  const productGroups = useAtomValue(productGroupsState);
  const [page, setPage] = useState(0);
  const numPages = Math.ceil(productGroups.length / GROUPS_PER_PAGE);
  const pageStart = page * GROUPS_PER_PAGE;
  const pageEnd = pageStart + GROUPS_PER_PAGE;

  if (!productGroups.length) {
    return null;
  }

  return (
    <div className="self-stretch inline-flex flex-col justify-start items-start gap-4">
      {showTitle && (
        <Typography className="font-semibold" color="brand.boldest.enabled">
          Featured product groups
        </Typography>
      )}
      <div className="self-stretch inline-flex justify-start items-start gap-4">
        {productGroups.slice(pageStart, pageEnd).map((productGroup, index) => (
          <ProductGroup
            productGroup={productGroup}
            key={`${index}-${productGroup.name}`}
          />
        ))}
      </div>
      <div className="self-stretch inline-flex justify-between items-center">
        <PaginatedListButtons index={page} count={numPages} onClick={setPage} />
      </div>
    </div>
  );
}
