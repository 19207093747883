import { type Getter, type Setter, atom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useEffect } from "react";
import {
  ApiService,
  type ProductServiceSearchResponse,
  type SupplierProductGroup,
} from "../generated";
import { matchesSupplier } from "../hooks/search/utils";
import { handleError } from "../utils/generatedApi";
import { isAuthenticatedState } from "./user";

const productInitializedState = atom(false);

const productLoadingState = atom(false);

export const productLastSearchQueryState = atom("");

const DEFAULT_PRODUCT_RESPONSE = {
  hits: [],
  count: 0,
  totalCount: 0,
  pageSize: 6,
  page: 0,
};

const initialProductResponseState = atom<ProductServiceSearchResponse>(
  DEFAULT_PRODUCT_RESPONSE
);

const productResponseState = atom<ProductServiceSearchResponse>(
  DEFAULT_PRODUCT_RESPONSE
);

export const productState = atom(
  (get) => {
    return {
      isInitialized: get(productInitializedState),
      isLoading: get(productLoadingState),
      initialProducts: get(initialProductResponseState),
      products: get(productResponseState),
      lastSearchQuery: get(productLastSearchQueryState),
    };
  },
  (
    _get,
    set,
    value: {
      isInitialized: boolean;
      isLoading: boolean;
      products: ProductServiceSearchResponse;
      lastSearchQuery: string;
    }
  ) => {
    set(productInitializedState, value.isInitialized);
    set(productLoadingState, value.isLoading);
    set(productResponseState, value.products);
    set(productLastSearchQueryState, value.lastSearchQuery);
  }
);

export const productGroupsState = atom<SupplierProductGroup[]>([]);

export const selectedProductGroupState =
  atom<Maybe<SupplierProductGroup>>(null);

export function useSearchProductServicesCallback() {
  const searchProductServices = useCallback(
    async (
      _get: Getter,
      set: Setter,
      supplierHandle: string,
      page: number,
      query: string,
      clearSelectedProductGroup = true
    ) => {
      try {
        set(productLoadingState, true);
        const response = await ApiService.apiV1ProductServicesRetrieve(
          supplierHandle,
          page,
          query
        );
        set(productResponseState, response);
        set(productLastSearchQueryState, query);
        if (clearSelectedProductGroup) {
          set(selectedProductGroupState, null);
        }
        set(productLoadingState, false);
        return response;
      } catch (error) {
        handleError(error);
        set(productResponseState, { ...DEFAULT_PRODUCT_RESPONSE });
        set(productLoadingState, false);
      }
    },
    []
  );
  return useAtomCallback(searchProductServices);
}

export function useListProductGroupsCallback() {
  const listProductGroups = useCallback(
    async (_get: Getter, set: Setter, supplierId: number, query: string) => {
      try {
        const response =
          await ApiService.apiV1SuppliersProductGroupsList(supplierId);
        set(productGroupsState, response);
        set(selectedProductGroupState, query ? null : response[0]);
      } catch (error) {
        handleError(error);
        set(productGroupsState, []);
        set(selectedProductGroupState, null);
      }
    },
    []
  );
  return useAtomCallback(listProductGroups);
}

function useInitializeProductServicesCallback() {
  const search = useSearchProductServicesCallback();
  const listProductGroups = useListProductGroupsCallback();

  const initialize = useCallback(
    (get: Getter, set: Setter) =>
      async (supplierHandle: string, supplierId: number, query: string) => {
        const product = get(productState);
        if (product.isInitialized) return;

        const [response, _] = await Promise.all([
          search(supplierHandle, 0, query, false),
          listProductGroups(supplierId, query),
        ]);
        if (response?.count) {
          set(initialProductResponseState, response);
        } else if (query !== "") {
          await search(supplierHandle, 0, "", false);
        }
        set(productInitializedState, true);
      },
    [search, listProductGroups]
  );
  return useAtomCallback(initialize);
}

export default function useInitializeProductServices({
  showProducts,
  handle,
  id,
  name,
  query,
}: {
  showProducts: boolean;
  handle: string;
  id: number;
  name: string;
  query: string;
}) {
  const initializeCallback = useInitializeProductServicesCallback();
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  useEffect(() => {
    if (!isAuthenticated || !showProducts) return;

    const includesSupplierName = matchesSupplier(query, handle, name);
    const initialize = initializeCallback();
    initialize(handle, id, includesSupplierName ? "" : query);
  }, [
    isAuthenticated,
    showProducts,
    handle,
    id,
    name,
    query,
    initializeCallback,
  ]);
}
